body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reloadButton {
  position: absolute;
  right: 0;
  top: 0;
}

.nameColumn {
  min-width: 148px;
}

.defaultColumn {
 min-width: 68px;
}

.copyStatusColumns,
.totalCopyColumn {
  background-color: #23232a1f;
  min-width: 54px;
}

.designStatusColumns,
.totalDesignColumn {
  background-color: #38383eb3;
  min-width: 54px;
}

.titleCell {
  min-width: 256px !important;
}

.actionCell:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0.9;
  cursor: pointer;
}

.headerFilterIcon {
  font-size: 1rem !important;
  margin-left: 10px;
  cursor: pointer;
}
